var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-2 mt-4"},[_c('v-data-table',{staticStyle:{"width":"99vw"},attrs:{"headers":_vm.headersVersoes,"items":_vm.dados,"item-key":"idversao","mobile-breakpoint":"50","show-expand":"","fixed-header":"","disable-pagination":"","hide-default-footer":"","ordered-by":"idversao","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.execNotProb",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.problems.exec.length == 0 ? 'success' : 'error'}},[_vm._v(_vm._s(item.problems.exec.length == 0 ? 'fa-check' : 'fa-close'))])]}},{key:"item.scriptNotProb",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.problems.script.length == 0 ? 'success' : 'error'}},[_vm._v(_vm._s(item.problems.script.length == 0 ? 'fa-check' : 'fa-close'))])]}},{key:"item.qtdExecs",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.execPrd.length)+" / "+_vm._s(item.execHml.length))]}},{key:"item.qtdScripts",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.scrpPrd.length)+" / "+_vm._s(item.scrpHml.length))]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.problems.exec.length > 0 || item.problems.script.length > 0)?_c('v-btn',{staticClass:"mb-0 mt-0",attrs:{"icon":"","x-small":"","data-open":isExpanded},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(_vm._s(isExpanded ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'))])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2 pl-4 elevation-1",staticStyle:{"background-color":"#E9E9E9"},attrs:{"colspan":headers.length}},[(!item.execNotProb )?_c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v("Executáveis")]),_c('v-data-table',{staticClass:"ft13 elevation-1",attrs:{"headers":_vm.headersExecutaveis,"items":item.problems.exec,"item-key":"a","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(item.name != item.nameHom ? ('color: red') : '')},[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(item.name))]),_c('div',{style:(item.hash != item.hashHom ? ('color: red') : '')},[_c('b',[_vm._v("Hash:")]),_vm._v(" "+_vm._s(item.hash))]),_c('div',{style:(item.url != item.urlHom ? ('color: red') : '')},[_c('b',[_vm._v("URL:")]),_vm._v(" "+_vm._s(item.url))])]}},{key:"item.homologacao",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(item.name != item.nameHom ? ('color: red') : '')},[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(item.nameHom))]),_c('div',{style:(item.hash != item.hashHom ? ('color: red') : '')},[_c('b',[_vm._v("Hash:")]),_vm._v(" "+_vm._s(item.hashHom))]),_c('div',{style:(item.url != item.urlHom ? ('color: red') : '')},[_c('b',[_vm._v("URL:")]),_vm._v(" "+_vm._s(item.urlHom))])]}}],null,true)})],1):_vm._e(),(!item.scriptNotProb)?_c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v("Scripts")]),_c('v-data-table',{staticClass:"ft13 elevation-1",attrs:{"headers":_vm.headersExecutaveis,"items":item.problems.script,"item-key":"a","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(item.idstudio != item.idstudioHom ? ('color: red') : '')},[_c('b',[_vm._v("idStudio:")]),_vm._v(" "+_vm._s(item.idstudio))]),_c('div',{style:(item.script != item.scriptHom ? ('color: red') : '')},[_c('b',[_vm._v("Script:")]),_vm._v(" "+_vm._s(item.script.substring(0,100))+"...")])]}},{key:"item.homologacao",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(item.idstudio != item.idstudioHom ? ('color: red') : '')},[_c('b',[_vm._v("idStudio:")]),_vm._v(" "+_vm._s(item.idstudioHom))]),_c('div',{style:(item.script != item.scriptHom ? ('color: red') : '')},[_c('b',[_vm._v("Script:")]),_vm._v(" "+_vm._s(item.scriptHom.substring(0,100))+"...")])]}}],null,true)})],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }