<template lang="pug">

v-card(class="ma-2 mt-4")
  v-data-table(:headers="headersVersoes"
      :items="dados"
      item-key="idversao"
      mobile-breakpoint="50"
      show-expand
      fixed-header
      disable-pagination
      hide-default-footer
      ordered-by="idversao"
      :expanded.sync="expanded"
      style="width: 99vw")

    template(v-slot:item.execNotProb="{ item }")
      v-icon(:color="item.problems.exec.length == 0 ? 'success' : 'error'" )
        | {{ item.problems.exec.length == 0 ? 'fa-check' : 'fa-close' }}

    template(v-slot:item.scriptNotProb="{ item }")
      v-icon(:color="item.problems.script.length == 0 ? 'success' : 'error'" )
        | {{ item.problems.script.length == 0 ? 'fa-check' : 'fa-close' }}

    template(v-slot:item.qtdExecs="{ item }")
      | {{ item.execPrd.length }} / {{ item.execHml.length }}

    template(v-slot:item.qtdScripts="{ item }")
      | {{ item.scrpPrd.length }} / {{ item.scrpHml.length }}

    template(v-slot:item.data-table-expand="{item, isExpanded, expand}")
      v-btn(v-if="item.problems.exec.length > 0 || item.problems.script.length > 0"
          icon x-small
          @click="expand(!isExpanded)"
          :data-open="isExpanded" class="mb-0 mt-0")
        v-icon(x-small)
          | {{ isExpanded ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down' }}

    template(v-slot:expanded-item="{ headers, item }")

      td(:colspan="headers.length"
          class="pa-2 pl-4 elevation-1"
          style="background-color: #E9E9E9")

        div(v-if="!item.execNotProb ")
          h4(class="mb-1") Executáveis
          v-data-table(:headers="headersExecutaveis"
              class="ft13 elevation-1"
              :items="item.problems.exec"
              item-key="a"
              disable-pagination
              hide-default-footer
              dense)

            template(v-slot:item.producao="{ item }")
              div(:style="item.name != item.nameHom ? ('color: red') : ''") <b>Nome:</b> {{ item.name }}
              div(:style="item.hash != item.hashHom ? ('color: red') : ''")  <b>Hash:</b> {{ item.hash }}
              div(:style="item.url != item.urlHom ? ('color: red') : ''")  <b>URL:</b> {{ item.url }}

            template(v-slot:item.homologacao="{ item }")
              div(:style="item.name != item.nameHom ? ('color: red') : ''")  <b>Nome:</b> {{ item.nameHom }}
              div(:style="item.hash != item.hashHom ? ('color: red') : ''")  <b>Hash:</b> {{ item.hashHom }}
              div(:style="item.url != item.urlHom ? ('color: red') : ''")  <b>URL:</b> {{ item.urlHom }}

        div(v-if="!item.scriptNotProb")
          h4(class="mb-1") Scripts
          v-data-table(:headers="headersExecutaveis"
              class="ft13 elevation-1"
              :items="item.problems.script"
              item-key="a"
              disable-pagination
              hide-default-footer
              dense)

            template(v-slot:item.producao="{ item }")
              div(:style="item.idstudio != item.idstudioHom ? ('color: red') : ''") <b>idStudio:</b> {{ item.idstudio }}
              div(:style="item.script != item.scriptHom ? ('color: red') : ''") <b>Script:</b> {{ item.script.substring(0,100) }}...

            template(v-slot:item.homologacao="{ item }")
              div(:style="item.idstudio != item.idstudioHom ? ('color: red') : ''") <b>idStudio:</b> {{ item.idstudioHom }}
              div(:style="item.script != item.scriptHom ? ('color: red') : ''") <b>Script:</b> {{ item.scriptHom.substring(0,100) }}...

</template>

<script>
import { mapMutations } from 'vuex';
import { auditoria } from '@/api';

export default {
  data: () => ({
    headersVersoes: [
      { value: 'idversao', text: 'id', width: 120 },
      { value: 'versao', text: 'Versão' },
      { value: 'qtdExecs', text: 'Execs Prod / Hom', align: 'center', width: 200 },
      { value: 'execNotProb', text: 'Executáveis', align: 'center', width: 150 },
      { value: 'qtdScripts', text: 'Scripts Prod / Hom', align: 'center', width: 200 },
      { value: 'scriptNotProb', text: 'Scripts', align: 'center', width: 150 },
      { value: 'data-table-expand', text: '' },
    ],
    headersExecutaveis: [
      { value: 'producao', text: 'Produção', width: 500 },
      { value: 'homologacao', text: 'Homologação', width: 500 },
    ],
    headersScripts: [
      { value: 'producao', text: 'Produção', width: 500 },
      { value: 'homologacao', text: 'Homologação', width: 500 },
    ],
    loaded: false,
    dados: [],
    expanded: [],
  }),
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    async buscarDados() {
      const preDados = [];

      function rowModel (idversao, versao, version, disponivel) {
        return {
          idversao,
          versao,
          version,
          disponivel,
          execPrd: [],
          scrpPrd: [],
          execHml: [],
          scrpHml: [],
          problems: {
            exec: [],
            script: [],
          },
        }
      }

      function rowModelProblemExec(nomeProd, hashProd, urlProd, nomeHom, hashHom, urlHom) {
        return {
          name: nomeProd,
          hash: hashProd,
          url: urlProd,
          nameHom: nomeHom,
          hashHom,
          urlHom,
        }
      }

      function rowModelProblemScript(idStudioProd, scriptProd, idStudioHom, scriptHom) {
        return {
          idstudio: idStudioProd,
          script: scriptProd,
          idstudioHom: idStudioHom,
          scriptHom,
        }
      }

      const fUrl = (s) => s.substring(s.lastIndexOf('/') + 1, s.length);
      const fIndex = (s) => preDados.findIndex((t) => t.version == s);

      this.showLoader('Carregando dados das versões...');
      // Recupera os dados dos banco de Produção e Homologação
      auditoria.prod.refreshAuthProd(this.$store.state.auth);
      auditoria.hom.refreshAuthHom(this.$store.state.auth);

      let versoesProd; let execPrd; let scrpPrd;
      let versoesHom; let execHml; let scrpHml;

      // Ambiente cruzado, a auditoria deve apresentar sempre as informações do
      // ambiente de produção vs ambiente de homologação, e o restos das funcionalidades
      // do gifnoc deve respeitar ao ambiente que esta logado.

      try {
        if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'homologation') {
          [versoesProd, execPrd, scrpPrd, versoesHom, execHml, scrpHml] = await Promise.all([
            auditoria.hom.getAll(),
            auditoria.hom.getExecutaveis(),
            auditoria.hom.getScripts(),
            auditoria.prod.getAll(),
            auditoria.prod.getExecutaveis(),
            auditoria.prod.getScripts()
          ]);
        } else {
          [versoesProd, execPrd, scrpPrd, versoesHom, execHml, scrpHml] = await Promise.all([
            auditoria.prod.getAll(),
            auditoria.prod.getExecutaveis(),
            auditoria.prod.getScripts(),
            auditoria.hom.getAll(),
            auditoria.hom.getExecutaveis(),
            auditoria.hom.getScripts()
          ]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.hideLoader();
      }

      // Filtra apenas as versões de 06/2022 até atualidade
      versoesProd = versoesProd.filter((r) => r.idversao >= 271); // versão 2022.6.1.1
      versoesHom = versoesHom.filter((r) => r.idversao >= 271); // versão 2022.6.1.1

      // Normaliza as versões
      versoesProd.forEach((r) => r.version = this.normalizaVersao(r.versao));
      versoesHom.forEach((r) => r.version = this.normalizaVersao(r.versao));

      // Normaliza os dados
      execPrd.forEach((r) => {
        let ver = versoesProd.find((t) => r.versao == t.idversao);
        r.version = ver ? ver.version : -1;
        r.name = fUrl(r.url);
      });
      scrpPrd.forEach((r) => { 
        let ver = versoesProd.find((t) => r.versao == t.idversao);
        r.version = ver ? ver.version : -1;
      });

      execHml.forEach((r) => {
        let ver = versoesHom.find((t) => r.versao == t.idversao);
        r.version = ver != undefined ? ver.version : -1;
        r.name = fUrl(r.url);
      });
      scrpHml.forEach((r) => { 
        let ver = versoesHom.find((t) => r.versao == t.idversao);
        r.version = ver != undefined ? ver.version : -1;
      });

      // Joga os dados de versão de producao para o modelo
      for (const row of versoesProd) {
        const t = rowModel(row.idversao, row.versao, row.version, row.disponivel);
        preDados.push(t);
      }

      // Distribui os dados
      for (const row of execPrd) if (fIndex(row.version) > -1) preDados[fIndex(row.version)].execPrd.push(row);
      for (const row of scrpPrd) if (fIndex(row.version) > -1) preDados[fIndex(row.version)].scrpPrd.push(row);
      for (const row of execHml) if (fIndex(row.version) > -1) preDados[fIndex(row.version)].execHml.push(row);
      for (const row of scrpHml) if (fIndex(row.version) > -1) preDados[fIndex(row.version)].scrpHml.push(row);

      // Regras para validação
      // Compara os dados em produção e homologação
      // e caso algo estiver diferente, joga para
      // variaveis especificas para serem exibidas na pagina
      preDados.forEach((row) => {
        for (const t of row.execPrd) {
          if (!row.execHml.some((a) => a.name === t.name)) {
            row.problems.exec.push(rowModelProblemExec(null, null, null, t.name, t.hash, t.url));
          } else {
            const n = row.execHml.find((a) => a.name == t.name);
            if (t.name !== n.name || t.url !== n.url || t.hash !== n.hash) {
              row.problems.exec.push(rowModelProblemExec(t.name, t.hash, t.url, n.name, n.hash, n.url));
            }
          }
        }

        for (const t of row.execHml) {
          if (!row.execPrd.some((a) => a.name == t.name)) {
            row.problems.exec.push(rowModelProblemExec(null, null, null, t.name, t.hash, t.url));
          } else {
            const n = row.execPrd.find((a) => a.name == t.name);
            if (t.name !== n.name || t.url !== n.url || t.hash !== n.hash) {
              row.problems.exec.push(rowModelProblemExec(t.name, t.hash, t.url, n.name, n.hash, n.url));
            }
          }
        }

        for (const t of row.scrpPrd) {
          if (!row.scrpHml.some((a) => a.idstudio == t.idstudio)) {
            row.problems.script.push(rowModelProblemScript(t.idstudio, t.script, '', ''));
          } else {
            const n = row.scrpHml.find((a) => a.idstudio == t.idstudio);
            if (t.idstudio !== n.idstudio || this.nscript(t.script) !== this.nscript(n.script)) {
              row.problems.script.push(rowModelProblemScript(t.idstudio, t.script, n.idstudio, n.script));
            }
          }
        }

        for (const t of row.scrpHml) {
          if (!row.scrpPrd.some((a) => a.idstudio == t.idstudio)) {
            row.problems.script.push(rowModelProblemScript('', '', t.idstudio, t.script));
          } else {
            const n = row.scrpPrd.find((a) => a.idstudio == t.idstudio);
            if (t.idstudio !== n.idstudio || this.nscript(t.script) !== this.nscript(n.script)) {
              row.problems.script.push(rowModelProblemScript(t.idstudio, t.script, n.idstudio, n.script));
            }
          }
        }
      });

      this.dados = preDados;
    },

    normalizaVersao(value) {
      return value.split('.').map((n) => parseInt(n).toString().padStart(2, '0')).join('');
    },

    nscript(value) {
      return value.trim().replaceAll(/[\n\r]/g, '');
    },

  },

  mounted() {
    this.isControl(true);
    this.setTitle('Auditoria de Versões');
    this.buscarDados();
  },

};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
